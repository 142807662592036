import { put, call, takeLatest } from 'redux-saga/effects';
import * as aT from '../actions/actionTypes';

let userApi = null;

function* getRecentTweets(payload) {
    yield put({ type: aT.SET_LOADING, data: true });
    yield put({ type: aT.SET_ERROR, data: null });
    try {
        const response = yield call(userApi, {
            method: 'GET',
            url: 'http://localhost:3000/data',
        });
        const res = response.data;
        // console.log('tweets', res)
        yield put({ type: aT.SET_RECENT_TWEETS, data: res });
        yield call(payload.onSuccess, res);
        yield put({ type: aT.SET_LOADING, data: false });
    } catch (err) {
        yield put({
            type: aT.SET_ERROR,
            // data: err.response.data.errors[0].message,
        });
        yield call(payload.onFailure);
        yield put({ type: aT.SET_LOADING, data: false });
    }
}


export function* getRecentTweetsSaga(customAxios) {
    userApi = customAxios;
    yield takeLatest(aT.GET_RECENT_TWEETS, getRecentTweets);
}