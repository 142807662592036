import * as aT from "./actionTypes";

export const getAptitude = (onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.GET_APTITUDE,
  onSuccess,
  onFailure,
});

export const getTechnical = (onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.GET_TECHNICAL,
  onSuccess,
  onFailure,
});