import { put, call, takeLatest } from 'redux-saga/effects';
import * as aT from '../actions/actionTypes';

let userApi = null;

function* getAptitude(payload) {
    yield put({ type: aT.SET_LOADING, data: true });
    yield put({ type: aT.SET_ERROR, data: null });
    try {
        const response = yield call(userApi, {
            method: 'GET',
            url: '/model/items/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14/qualifier_questions?limit=-1',
        });
        const res = response.data;
        yield put({ type: aT.SET_APTITUDE, data: res });
        yield put({ type: aT.SET_LOADING, data: false });
        yield call(payload.onSuccess, res);
    } catch (err) {
        yield put({
            type: aT.SET_ERROR,
            data: err.response?.data.errors[0].message,
        });
        yield put({ type: aT.SET_LOADING, data: false });
        yield call(payload.onFailure, err.response.data.errors[0].message);
    }
}

function* getTechnical(payload) {
    yield put({ type: aT.SET_LOADING, data: true });
    yield put({ type: aT.SET_ERROR, data: null });
    try {
        const response = yield call(userApi, {
            method: 'GET',
            url: '/model/items/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14/tech_aptitude_questions?limit=-1',
        });
        const res = response.data;
        yield put({ type: aT.SET_TECHNICAL, data: res });
        yield put({ type: aT.SET_LOADING, data: false });
        yield call(payload.onSuccess, res);
    } catch (err) {
        yield put({
            type: aT.SET_ERROR,
            data: err.response?.data.errors[0].message,
        });
        yield put({ type: aT.SET_LOADING, data: false });
        yield call(payload.onFailure, err.response.data.errors[0].message);
    }
}

export function* getAptitudeSaga(customAxios) {
    userApi = customAxios;
    yield takeLatest(aT.GET_APTITUDE, getAptitude);
}

export function* getTechnicalSaga(customAxios) {
    userApi = customAxios;
    yield takeLatest(aT.GET_TECHNICAL, getTechnical);
}