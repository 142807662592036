import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import Login from "./login";
import Dashboard from "./dashboard";
import Profile from "./profile";
import Evaluation from "./evaluation";

function Main() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/profile">
          <Profile />
        </PrivateRoute>
        <PrivateRoute path="/evaluation">
          <Evaluation />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default Main;
