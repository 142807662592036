import React from "react";

const SectionTitle = (props) => {
  return (
          <div className="">
            <small>{props.subTitle}</small>
            <h2 className="font-bold text-xl -mt-2">{props.title}</h2>
          </div>
  );
};

export default SectionTitle;