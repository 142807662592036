import React from 'react';
import TechnicalContainer from './components/technicalContainer'

const Aptitude = (props) => {
  return (
    <>
      <div className="main-wrap flex flex-col justify-start bg-transparent">
        <TechnicalContainer />
      </div>
    </>
  );
};

export default Aptitude;
