import React from "react";
import Header from "./header";

const FullPage = (props) => {
  return (
    <div className="flex flex-col p-2 md:p-12 main">
      {!props.isLogin && <Header />}
      <main className="flex-grow">{props.children}</main>
    </div>
  );
};

export default FullPage;
