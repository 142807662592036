import axios from "axios";

export const api = axios.create({
  baseURL: "https://ecs-prd0-core.fscms.co/",
});

const errorHandler = (error) => {
  const thisError = { ...error };
  return Promise.reject(thisError);
};

const successHandler = (response) => response;

api.interceptors.request.use((request) => (request));
api.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
);
