import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import FullPage from '../components/layout/fullPage';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, getUser, userLogin } from '../store/actions';
import Banneriamge from '../assets/images/fs-banner.png';

require('dotenv').config();

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (localStorage.getItem('fsUserID')) {
      history.push('/dashboard');
    }
    // eslint-disable-next-line
  }, []);

  const tryLogin = (data) => {
    dispatch(userLogin({email: getValues('email'), password: getValues('phone'), 
        orgId: "a6371fd4-8364-4f00-a437-85aae9a94989",
        projectId: "a199773e-168b-4cc8-80b6-e696968ddd14"
    }, loginSuccess, loginFailure));
  };

  const loginSuccess = (res) => {
    localStorage.setItem('fsAT', res.access_token);
    localStorage.setItem('fsRT', res.refresh_token);
    getTheUser(getValues('email'));
  };

  const loginFailure = (err) => {
      console.log(err);
  };

  // const createSuccess = (res) => {
  //   dispatch(userLogin({email: getValues('email'), password: getValues('phone')}, loginSuccess, loginFailure));
  // };

  // const createFailure = (err) => {
  //   if(err === `Field "email" has to be unique.`){
  //     dispatch(userLogin({email: getValues('email'), password: getValues('phone'), 
  //       orgId: "a6371fd4-8364-4f00-a437-85aae9a94989",
  //       projectId: "a199773e-168b-4cc8-80b6-e696968ddd14"
  //   }, loginSuccess, loginFailure));
  //   } else{
  //     console.log(err);
  //   }
  // };

  const getTheUser = (data) => {
    dispatch(getUser(data, getUserSuccess, getUserFailure));
  };

  const getUserSuccess = (res) => {
    localStorage.setItem('fsUserID', res.id);
    history.push('/dashboard');
  };

  const getUserFailure = (err) => {
    console.log(err);
  };

  return (
    <FullPage isLogin>
      <div className="grid grid-cols-1 items-center md:grid-cols-3 md:grid-flow-col grid-flow-row gap-4">
        <div className="md:col-span-1 row-span-1 z-10 md:-mr-24 ">
          <div className="py-10 bg-white border border-gray-200 shadow-md">
            <div className="md:grid md:gap-6">
              <div className="md:row-span-1">
                <div className="px-8">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    FS InsightX
                  </h3>
                  <h3 className="text-2xl font-bold mb-4 leading-6 text-gray-900">
                    Help us get to know you better
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    Know more about life at{' '}
                    <a href="https://fleetstudio.com/careers/" target="_blank" className="underline" rel="noreferrer">
                      Fleet Studio
                    </a>
                  </p>
                </div>
              </div>
              <div className="mt-5 md:mt-0 md:row-span-1">
                <form onSubmit={handleSubmit(tryLogin)}>
                  <div className="">
                    <div className="px-8 my-6 bg-white">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 border border-gray-200 rounded-md relative">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700 bg-white mb-2"
                          >
                            Email address
                          </label>
                          <input
                            type="email"
                            {...register('email', { required: true })}
                            name="email"
                            id="email"
                            required
                            className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                          />
                        </div>
                        {errors.email && (
                          <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                            *This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="px-8 bg-white">
                      <div className="grid grid-cols-6 gap-6">
                        <div className="col-span-6 border border-gray-200 rounded-md relative">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700 bg-white mb-2"
                          >
                            Phone Number
                          </label>
                          <input
                            type="text"
                            {...register('phone', { required: true })}
                            name="phone"
                            id="phone"
                            required
                            className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                          />
                        </div>
                        {errors.phone && (
                          <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                            *This field is required
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="px-8 pt-8 pb-0 text-right">
                      <button
                        type="submit"
                        disabled={state.app.isLoading}
                        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none"
                      >
                        {state.app.isLoading ? '...' : 'Begin'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              {state.app.error && (
                <div className="px-8 text-red-500 text-sm">
                  {state.app.error}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="md:col-span-2 row-span-1 bg-white z-0 md:min-bh-full shadow-lg">
          <img
            src={Banneriamge}
            alt="..."
            className={'object-cover md:min-bh-full mx-auto'}
          />
        </div>
      </div>
    </FullPage>
  );
};

export default Login;
