import * as aT from "../actions/actionTypes";

const initialState = {
    posts: []
};

export const leadershipReducer = (state = initialState, action) => {
    switch (action.type) {
        case aT.GET_LEADERSHIP:
            return { ...state };

        case aT.SET_LEADERSHIP:
            return { ...state, posts: action.data };

        default:
            return state;
    }
};
