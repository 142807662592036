export const FN = () => { };
export const USER_LOGIN = "USER_LOGIN";
export const GET_USER = "GET_USER";
export const CREATE_USER = "CREATE_USER";
export const GET_USER_BY_ID = "GET_USER_BY_ID";
export const STORE_USER = "STORE_USER";
export const STORE_USER_PROFILE = "STORE_USER_PROFILE";
export const SET_APPLICATION = "SET_APPLICATION";
export const GET_APPLICATION = "GET_APPLICATION";
export const UPDATE_APPLICATION = "UPDATE_APPLICATION";

export const GET_APTITUDE = "GET_APTITUDE";
export const SET_APTITUDE = "SET_APTITUDE";
export const GET_TECHNICAL = "GET_TECHNICAL";
export const SET_TECHNICAL = "SET_TECHNICAL";

export const GET_LEADERSHIP = "GET_LEADERSHIP";
export const SET_LEADERSHIP = "SET_LEADERSHIP";

export const GET_RECENT_TWEETS = "GET_RECENT_TWEETS";
export const SET_RECENT_TWEETS = "SET_RECENT_TWEETS";

export const SET_LOADING = "SET_LOADING";
export const SET_ERROR = "SET_ERROR";


