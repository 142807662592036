import { combineReducers } from "redux";
import { userReducer } from "./user";
import { questionsReducer } from "./questions";
import { leadershipReducer } from './leadership';
import { tweetsReducer } from "./recentTweets";
import { appReducer } from "./app";

const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  questions: questionsReducer,
  leadership: leadershipReducer,
  tweets: tweetsReducer,
});

export default rootReducer;
