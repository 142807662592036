import * as aT from "../actions/actionTypes";

const initialState = {
    recentTweets: []
};

export const tweetsReducer = (state = initialState, action) => {
    switch (action.type) {
        case aT.GET_RECENT_TWEETS:
            return { ...state };

        case aT.SET_RECENT_TWEETS:
            return { ...state, recentTweets: action.data };

        default:
            return state;
    }
};
