import React, { useEffect, useState } from 'react';
import FullPage from '../components/layout/fullPage';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SpellCheck32, UserMilitary32 } from '@carbon/icons-react';
import Banneriamge from '../assets/images/fs-banner.png';
import TwitterContainer from './dashboardContainer/TwitterContainer';
import LeadershipContainer from './dashboardContainer/leadershipContainer';
import { getUserByID } from '../store/actions';

const Dashboard = () => {
  const history = useHistory();
  const state = useSelector((state) => state);
  const loading = useSelector((state) => state.app.isLoading);
  const user = useSelector((state) => state.user.profile);

  const dispatch = useDispatch();

  useEffect(() => {
    const localID = localStorage.getItem('fsUserID');
    const profile = state.user.profile;
    if (localID) {
      dispatch(getUserByID(localID), null, notGotUser);
    }
    // eslint-disable-next-line
  }, []);

  const notGotUser = (err) => {
    localStorage.removeItem('fsAT');
    localStorage.removeItem('fsRT');
    localStorage.removeItem('fsUserID');
    history.push('/');
  };

  return (
    <FullPage>
      {loading ? (
        <>...</>
      ) : (
        <>
          <div className="min-h-full">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4  mb-4">
              <div className="h-64 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-end">
                <div className="text-sm text-gray-600">Howdy! 👋</div>
                <p className="text-2xl font-bold">
                  {user && user.first_name} {user && user.last_name}
                </p>
              </div>
              {/* <div className="h-64 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-end relative">
                <UserMilitary32 className=" text-gray-400 absolute top-8 right-8 w-20 h-20" />
                <div className="text-sm text-gray-600">Manage</div>
                <p className="text-2xl font-bold">Your Profile</p>
              </div> */}
              <button
                className="h-64 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-end relative hover:cursor-pointer"
                onClick={() => {
                  history.push('/evaluation');
                }}
              >
                <SpellCheck32 className=" text-gray-400 absolute top-8 right-8 w-20 h-20 " />
                <div className="text-sm text-gray-600">Start / Manage</div>
                <p className="text-2xl font-bold">Your Evaluation</p>
              </button>
            </div>
            <div className="bg-white z-0 shadow-lg mb-4">
              <img
                src={Banneriamge}
                alt="..."
                className={'object-cover mx-auto'}
              />
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full flex flex-wrap">
              <div className="h-72 bg-white main-wrap p-8 border border-gray-200 shadow-md md:col-span-2">
                <div className="text-sm text-gray-600">Latest Tweets</div>
                <p className="text-2xl font-bold">@FleetStudio</p>
                <div className="h-72 bg-white main-wrap mt-4">
                  <TwitterContainer />
                </div>
              </div>
              <div className="max-h-full md:max-h-screen bg-white main-wrap p-8 border border-gray-200 shadow-md">
                <div className="text-sm text-gray-600">Latest News</div>
                <p className="text-2xl font-bold">Thought Leadership</p>
                <LeadershipContainer />
              </div>
            </div> */}
          </div>
        </>
      )}
    </FullPage>
  );
};

export default Dashboard;
