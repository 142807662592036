import * as aT from "./actionTypes";

export const userLogin = (data, onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.USER_LOGIN,
  data,
  onSuccess,
  onFailure,
});

export const getUser = (data, onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.GET_USER,
  data,
  onSuccess,
  onFailure,
});

export const createUser = (data, onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.CREATE_USER,
  data,
  onSuccess,
  onFailure,
});

export const getUserByID = (data, onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.GET_USER_BY_ID,
  data,
  onSuccess,
  onFailure,
});

export const getApplication = (id, onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.GET_APPLICATION,
  id,
  onSuccess,
  onFailure,
});

export const updateApplication = (data, onSuccess = aT.FN, onFailure = aT.FN) => ({
  type: aT.UPDATE_APPLICATION,
  data,
  onSuccess,
  onFailure,
});