import React, { Fragment, useState } from 'react';
import FullPage from '../components/layout/fullPage';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import SectionTitle from '../components/typography/sectionTitle';
import PageHeader from '../components/layout/pageHeader';
import { UserMilitary32,AddAlt24,ArrowRight24  } from '@carbon/icons-react';

const Profile = () => {

  const loading = useSelector((state) => state.app.isLoading);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submit = (data) => {
    console.log(data);
  };

  const [inputFields, setInputFields] = useState([
    {skill: '', level_of_proficiency: ''},
  ]);

  const handleAddFields = () => {
    setInputFields([...inputFields, {skill: '', level_of_proficiency: ''}]);
  }

  return (
    <FullPage>
      {loading ? (
        <>
          <div className="min-h-full">
            <PageHeader
              title="Your Profile"
              subTitle="Manage"
              icon={<UserMilitary32 />}
            />
            <div className="h-72 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-end" />
          </div>
        </>
      ) : (
        <>
          <div className="min-h-full">
            <PageHeader
              title="Your Profile"
              subTitle="Manage"
              icon={<UserMilitary32 />}
            />
            <div className="h-72 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-start">
              <SectionTitle title="General Info" subTitle="Personal" />
              <form onSubmit={handleSubmit(submit)}>
                <div className="my-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-3 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        {...register('first_name', { required: true })}
                        name="first_name"
                        id="first_name"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.first_name && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>
                    <div className="col-span-3 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Last Name
                      </label>
                      <input
                        type="text"
                        {...register('last_name', { required: true })}
                        name="last_name"
                        id="last_name"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.last_name && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>
                    <div className="col-span-3 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="gender"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Gender
                      </label>
                      <select
                        {...register('gender', { required: true })}
                        name="gender"
                        id="gender"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md">
                        <option value="grapefruit">Male</option>
                        <option value="lime">Female</option>
                        <option value="coconut">Others</option>
                      </select>
                      {errors.gender && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>
                    <div className="col-span-3 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="date_of_birth"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        {...register('date_of_birth', { required: true })}
                        name="date_of_birth"
                        id="date_of_birth"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                        />
                      {errors.date_of_birth && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <SectionTitle title="Contact Info" subTitle="Personal" />
                <div className="my-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-3 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="email_address"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        {...register('email_address', { required: true })}
                        name="email_address"
                        id="email_address"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.email_address && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>
                    <div className="col-span-3 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Phone
                      </label>
                      <input
                        type="text"
                        {...register('phone', { required: true })}
                        name="phone"
                        id="phone"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.phone && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-6 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="address"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Address
                      </label>
                      <input
                        type="text"
                        {...register('address', { required: true })}
                        name="address"
                        id="address"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.address && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        City
                      </label>
                      <input
                        type="text"
                        {...register('city', { required: true })}
                        name="city"
                        id="city"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.city && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="state"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        State
                      </label>
                      <input
                        type="text"
                        {...register('state', { required: true })}
                        name="state"
                        id="state"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.state && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="zipcode"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Zipcode
                      </label>
                      <input
                        type="text"
                        {...register('zipcode', { required: true })}
                        name="zipcode"
                        id="zipcode"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.zipcode && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <SectionTitle title="Experience Info" subTitle="Personal" />
                <div className="my-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="highest_educational_qualification"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Highest Educational Qualification
                      </label>
                      <select
                        {...register('highest_educational_qualification', { required: true })}
                        name="highest_educational_qualification"
                        id="highest_educational_qualification"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md">
                        <option value="">Select</option>
                        <option value="under_graduate">Under Graduate</option>
                        <option value="post_graduate">Post Graduate</option>
                        <option value="diploma">Diploma</option>
                        <option value="others">Others</option>
                      </select>
                      {errors.highest_educational_qualification && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="years_of_experience"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Number of years of professional experience
                      </label>
                      <input
                        type="number"
                        {...register('years_of_experience', { required: true })}
                        name="years_of_experience"
                        id="years_of_experience"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.years_of_experience && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="current_employer"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Current Employer
                      </label>
                      <input
                        type="text"
                        {...register('current_employer', { required: true })}
                        name="current_employer"
                        id="current_employer"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.current_employer && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="current_ctc"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Current CTC
                      </label>
                      <input
                        type="text"
                        {...register('current_ctc', { required: true })}
                        name="current_ctc"
                        id="current_ctc"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.current_ctc && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>

                    <div className="col-span-2 border border-gray-200 rounded-md relative">
                      <label
                        htmlFor="expected_ctc"
                        className="block text-sm font-medium text-gray-700 bg-white mb-2"
                      >
                        Expected CTC
                      </label>
                      <input
                        type="text"
                        {...register('expected_ctc', { required: true })}
                        name="expected_ctc"
                        id="expected_ctc"
                        required
                        className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                      />
                      {errors.expected_ctc && (
                        <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                          *This field is required
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <SectionTitle title="Skill Info" subTitle="Professional" />
                <div className="my-6">
                  
                  { inputFields.map((inputField, ind) => {
                    var tmp_index = {ind};
                    return (<div className="my-6" key={ind}>
                      <div className="grid grid-cols-6 gap-6">
                       <div className="col-span-3 border border-gray-200 rounded-md relative">
                          <label
                            htmlFor={'skill'+tmp_index.ind}
                            className="block text-sm font-medium text-gray-700 bg-white mb-2"
                          >
                            Skill
                          </label>
                          <input
                            type="text"
                            {...register('skill['+tmp_index.ind+']', { required: true })}
                            name={'skill['+tmp_index.ind+']'}
                            
                            required
                            className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md"
                          />
                          {errors.skill && (
                            <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                              *This field is required
                            </span>
                          )}
                        </div>

                        <div className="col-span-3 border border-gray-200 rounded-md relative">
                          <label
                            htmlFor="level_of_proficiency"
                            className="block text-sm font-medium text-gray-700 bg-white mb-2"
                          >
                            Choose the level of proficiency
                          </label>
                          <select
                            {...register('level_of_proficiency['+tmp_index.ind+']', { required: true })}
                            name={'level_of_proficiency['+tmp_index.ind+']'}
                            
                            required
                            className="py-4 px-4 focus:outline-none focus:border-gray-300 block w-full bg-gray-100 sm:text-sm border-gray-200 border rounded-md">
                            <option value="">Select</option>
                            <option value="E">Expert</option>
                            <option value="A">Advanced</option>
                            <option value="I">Intermediate</option>
                            <option value="B">Beginner</option>
                          </select>
                          {errors.level_of_proficiency && (
                            <span className="col-span-6 -m-4 px-8 text-xs text-red-400">
                              *This field is required
                            </span>
                          )}
                        </div>
                      </div>
                      </div>);

                   } ) }

                  <button type="button" onClick={() => handleAddFields()} className="profileSubmit inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium text-blue bg-white-600 hover:bg-blue-700 hover:text-white focus:outline-none">Add Another Skill&nbsp;&nbsp;{<AddAlt24 />}</button>
                </div>
                <button type="submit" name="profileInfo" className="profileInfo inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium text-blue bg-white-600 hover:bg-blue-700 hover:text-white focus:outline-none">Save Changes &nbsp;&nbsp;<ArrowRight24 /></button>
              </form>
            </div>
          </div>
        </>
      )}
    </FullPage>
  );
};

export default Profile;