import React from "react";

const pageHeader = (props) => {
  return (
    <div className="p-6 min-w-full border-solid border-b bg-white shadow-lg border-gray-200">
      <div className="flex items-center justify-center">
        <div className="flex-grow page-header--title_wrap flex items-center justify-center">
          <div className="mr-2">{props.icon}</div>
          <div className="flex-grow">
            <small>{props.subTitle}</small>
            <h2 className="font-bold text-xl -mt-2">{props.title}</h2>
          </div>
        </div>
        <div className="page-header--action_wrap">{props.action}</div>
      </div>
    </div>
  );
};

export default pageHeader;
