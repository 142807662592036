import { SET_LOADING, SET_ERROR } from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  error: null,
};

export const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return { ...state, isLoading: action.data };

    case SET_ERROR:
      return { ...state, error: action.data };

    default:
      return state;
  }
};
