import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Startbutton from './technicalStartbutton';
import moment from 'moment';
import momentz from 'moment-timezone';
import { getTechnical } from '../../../store/actions';
import { updateApplication } from '../../../store/actions';
import { Button } from 'carbon-components-react';
import { ArrowLeft24, ArrowRight24 } from '@carbon/icons-react';

const TechnicalContianer = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [timeLeft, setTimeLeft] = useState();
  const [currentQn, setCurrentQn] = useState(0);
  const [qn, setQn] = useState(null);
  const [answer, setAnswer] = useState(null);
  const [loading, setLoading] = useState(false);
  let timeTracker;

  const startTimer = () => {
    timeTracker = setInterval(setTimeRemaining, 1000);
    const rawQns = state.user.application.technical_evaluation_questions;
    const qn = rawQns[0];
    setQn(qn);
    if(qn?.chosenAnswer){
        setAnswer(qn?.chosenAnswer);
    }
    setLoading(false);
  };

  const setTimeRemaining = () => {
    const proposedEndTime = moment(
      state.user.application.technical_evaluation_session_start
    )
      .local()
      .add(30, 'minutes');
    const timeRemainingFromNow = moment.utc();
    const diff = moment
      .duration(proposedEndTime.diff(timeRemainingFromNow))
      .asSeconds();
    const diffMins = `${Math.floor(diff / 60)}:${Math.floor(
        diff - Math.floor(diff / 60) * 60
    )}`;
    if(diff<0){
      submit();
    }else{
    setTimeLeft(diffMins);
    }
  };

  const getQn = () => {
    if (
      state.user.application &&
      state.user.application.technical_evaluation_questions
    ) {
      const rawQns = state.user.application.technical_evaluation_questions;
      const qn = rawQns[currentQn];

      return qn?.question;
    } else {
      return null;
    }
  };

  const getChoice = (id) => {
    if (
      state.user.application &&
      state.user.application.technical_evaluation_questions
    ) {
      const rawQns = state.user.application.technical_evaluation_questions;
      const qn = rawQns[currentQn];
      if (id === 1) {
        return qn?.choice_1;
      } else if (id === 2) {
        return qn?.choice_2;
      } else if (id === 3) {
        return qn?.choice_3;
      } else if (id === 4) {
        return qn?.choice_4;
      }
    } else {
      return null;
    }
  };
  useEffect(() => {
    if (state.questions.technical.length === 0) {
      dispatch(getTechnical(), null, null);
    }
    if(qn){
      console.log("");
    }
    if (
      state.user &&
      state.user.application &&
      state.user.application.technical_evaluation_session_start &&
      !state.user.application.technical_evaluation_session_end
    ) {
      startTimer();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      state.user &&
      state.user.application &&
      state.user.application.technical_evaluation_session_start &&
      !state.user.application.technical_evaluation_session_end
    ) {
      startTimer();
    }
    // eslint-disable-next-line
  }, [state.user.application]);

  useEffect(() => {
    if (timeLeft === '0:0') {
      clearInterval(timeTracker);
      const data = {
        id: state.user.application.id,
        body: {
          technical_evaluation_session_end: momentz.tz('Asia/Kolkata').format(),
        },
      };
      dispatch(updateApplication(data, null, null));
    } else if(timeLeft < '0:0'){
      clearInterval(timeTracker);
    }
    // eslint-disable-next-line
  }, [timeLeft]);

  const goToPrevious = () => {
    setLoading(true);
    if (answer) {
        const rawQns = state.user.application.technical_evaluation_questions;
        const qnID = rawQns[currentQn].id;
        const updatedQns = rawQns.map((qn) => {
          if (qn.id === qnID) {
            return { ...qn, chosenAnswer: parseInt(answer) };
          } else {
            return qn;
          }
        });
        const data = {
          id: state.user.application.id,
          body: {
            technical_evaluation_questions: JSON.stringify(updatedQns),
          },
        };
        dispatch(updateApplication(data, updatePreviousSuccess, null));
      } else {
        updatePreviousSuccess();
      }
  };

  const goToNext = () => {
    setLoading(true);
    if (answer) {
      const rawQns = state.user.application.technical_evaluation_questions;
      const qnID = rawQns[currentQn].id;
      const updatedQns = rawQns.map((qn) => {
        if (qn.id === qnID) {
          return { ...qn, chosenAnswer: parseInt(answer) };
        } else {
          return qn;
        }
      });
      const data = {
        id: state.user.application.id,
        body: {
          technical_evaluation_questions: JSON.stringify(updatedQns),
        },
      };
      dispatch(updateApplication(data, updateNextSuccess, null));
    } else {
      updateNextSuccess();
    }
  };

  const submit = () => {
    setLoading(true);
    if (answer) {
      const rawQns = state.user.application.technical_evaluation_questions;
      const qnID = rawQns[currentQn].id;
      const updatedQns = rawQns.map((qn) => {
        if (qn.id === qnID) {
          return { ...qn, chosenAnswer: parseInt(answer) };
        } else {
          return qn;
        }
      });
      const data = {
        id: state.user.application.id,
        body: {
          technical_evaluation_questions: JSON.stringify(updatedQns),
        },
      };
      dispatch(updateApplication(data, updateSubmitSuccess, null));
    } else {
        updateSubmitSuccess();
    }
  };

  const updateSubmitSuccess = () => {
    clearInterval(timeTracker);
    const data = {
      id: state.user.application.id,
      body: {
        technical_evaluation_session_end: momentz.tz('Asia/Kolkata').format(),
      },
    };
    dispatch(updateApplication(data, null, null));
  }

  const updatePreviousSuccess = (data) => {
    const rawQns = state.user.application.technical_evaluation_questions;
    const nextId = currentQn - 1;
    const qnin = rawQns[nextId];
    setQn(qnin);
    if(qnin?.chosenAnswer){
        setAnswer(qnin?.chosenAnswer);
    } else {
        setAnswer(null);
    }
    setCurrentQn(nextId);
    setLoading(false);
  };

  const updateNextSuccess = (data) => {
    const rawQns = state.user.application.technical_evaluation_questions;
    const nextId = currentQn + 1;
    const qnin = rawQns[nextId];
    setQn(qnin);
    if(qnin?.chosenAnswer){
        setAnswer(qnin?.chosenAnswer);
    } else {
      setAnswer(null);
    }
    setCurrentQn(nextId);
    setLoading(false);
  };

  return (
      <>
        {state.user.application &&
        state.user.application.technical_evaluation_session_end ? (
          <div className="bg-white p-8 border border-gray-200 shadow-md">
            <div className="min-w-full flex flex-col justify-center items-center p-16">
              <p className="text-sm mx-auto text-center my-4">
              <span className="font-bold">
                You have successfully taken the technical evaluation.
              </span>
              <br /> Please contact the Fleet Studio HR Team for further
              information.
              </p>
            </div>
          </div>
        ) : !state.user.application ||
          !state.user.application.technical_evaluation_session_start ? (
          <div className="bg-white p-8 border border-gray-200 shadow-md">
            <Startbutton startCountdown={startTimer} />
          </div>
        ) : (
          <>
            <div className="bg-white border border-gray-200 shadow-md mb-3 flex-row items-center flex w-auto">
              <p className="w-auto p-6 flex-grow">
                <span className="text-2xl font-bold">
                  Question {currentQn + 1}
                </span>
                <span className="text-sm text-gray-600">/20</span>
              </p>
              <div className="px-5 text-right">
                <div className="text-2xl -mb-7">{timeLeft}</div>
                <br />
                <small className="">Time Remaining</small>
              </div>
              <div
                className="text-right p-4 cursor-pointer flex items-center border-l"
                onClick={() => !(currentQn <= 0) && !loading && goToPrevious()}
              >
                <ArrowLeft24 />
                <div className="pl-4">
                  Previous
                  <br />
                  Question
                </div>
              </div>
              <div
                className="p-4 cursor-pointer flex items-center border-l"
                onClick={() => !(currentQn >= 19) && !loading && goToNext()}
              >
                <div className="pr-4">
                  Next
                  <br />
                  Question
                </div>
                <ArrowRight24 />
              </div>
              <div className="">
                <Button size="xl" onClick={() => submit()} disabled={loading}>{loading ? <span>Loading... Please wait</span> : <span>Submit &amp; End Evaluation</span>}</Button>
              </div>
            </div>
            <div className="bg-white p-8 border border-gray-200 shadow-md">
              <div
                dangerouslySetInnerHTML={{ __html: getQn() }}
                className="pb-4 text-xl"
              />
              <div>
                <div className="flex mb-2 relative">
                  <input
                    type="radio"
                    id="choice1"
                    className="relative top-1"
                    name="answer"
                    value={1}
                    // eslint-disable-next-line
                    checked={answer == 1 ? true : false}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  <label className="pl-1" htmlFor="choice1">
                    {getChoice(1)}
                  </label>
                </div>
                <div className="flex mb-2">
                  <input
                    type="radio"
                    id="choice2"
                    name="answer"
                    className="relative top-1"
                    value={2}
                    // eslint-disable-next-line
                    checked={answer == 2 ? true : false}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  <label className="pl-1" htmlFor="choice2">
                    {getChoice(2)}
                  </label>
                </div>
                <div className="flex mb-2">
                  <input
                    type="radio"
                    id="choice3"
                    className="relative top-1"
                    name="answer"
                    value={3}
                    // eslint-disable-next-line
                    checked={answer == 3 ? true : false}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  <label className="pl-1" htmlFor="choice3">
                    {getChoice(3)}
                  </label>
                </div>
                <div className="flex mb-2">
                  <input
                    type="radio"
                    id="choice4"
                    className="relative top-1"
                    name="answer"
                    value={4}
                    // eslint-disable-next-line
                    checked={answer == 4 ? true : false}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  <label className="pl-1" htmlFor="choice4">
                    {getChoice(4)}
                  </label>
                </div>
              </div>
            </div>
          </>
        )}
      </>
  );
};

export default TechnicalContianer;
