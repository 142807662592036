import { put, call, takeLatest } from 'redux-saga/effects';
import * as aT from '../actions/actionTypes';

let userApi = null;

function* login(payload) {
  yield put({ type: aT.SET_LOADING, data: true });
  yield put({ type: aT.SET_ERROR, data: null });
  const data = { ...payload.data, handlerEnabled: false };
  try {
    const response = yield call(userApi, {
      method: 'POST',
      data,
      url: '/auth/login/external',
    });
    const res = response.data.data;
    yield put({ type: aT.STORE_USER, data: res });
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onSuccess, res);
  } catch (err) {
    yield put({
      type: aT.SET_ERROR,
      data: err.response?.data.errors[0].message,
    });
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onFailure, err.response.data.errors[0].message);
  }
}

function* getUser(payload) {
  yield put({ type: aT.SET_LOADING, data: true });
  yield put({ type: aT.SET_ERROR, data: null });
  const data = payload.data;
  try {
    const response = yield call(userApi, {
      method: 'GET',
      url: `/users/members/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14?filter[email][_eq]=${data}`,
    });
    const res = response.data.data[0];
    yield put({ type: aT.STORE_USER_PROFILE, data: res });
    try {
      const resp = yield call(userApi, {
        method: 'GET',
        url: `/model/items/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14/job_applications?filter[id][_eq]=${res.job_applications[0]}`,
      });
      const application = resp.data.data[0];
      yield put({ type: aT.SET_APPLICATION, data: {...application, aptitude_evaluation_questions: JSON.parse(application.aptitude_evaluation_questions), technical_evaluation_questions: JSON.parse(application.technical_evaluation_questions)} });
    } catch (err) {
      yield put({
        type: aT.SET_ERROR,
        data: err.response?.data?.errors[0]?.message,
      });
    }
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onSuccess, res);
  } catch (err) {
    if (
      err.response?.data?.errors[0]?.message !== `Field "email" has to be unique.`
    ) {
      yield put({
        type: aT.SET_ERROR,
        data: err.response?.data.errors[0].message,
      });
    }
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onFailure, err.response?.data?.errors[0]?.message);
  }
}

function* createUser(payload) {
  yield put({ type: aT.SET_LOADING, data: true });
  yield put({ type: aT.SET_ERROR, data: null });
  const data = { ...payload.data, handlerEnabled: false };
  try {
    const response = yield call(userApi, {
      method: 'POST',
      url: `/users`,
      data,
    });
    const res = response.data.data[0];
    yield put({ type: aT.STORE_USER_PROFILE, data: res });
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onSuccess, res);
  } catch (err) {
    yield put({
      type: aT.SET_ERROR,
      data: err.response?.data.errors[0].message,
    });
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onFailure, err.response.data.errors[0].message);
  }
}

function* getUserByID(payload) {
  yield put({ type: aT.SET_LOADING, data: true });
  yield put({ type: aT.SET_ERROR, data: null });
  const data = payload.data;
  try {
    const response = yield call(userApi, {
      method: 'GET',
      url: `/users/members/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14/${data}`,
    });
    const res = response.data.data;
    yield put({ type: aT.STORE_USER_PROFILE, data: res });
    try {
      const resp = yield call(userApi, {
        method: 'GET',
        url: `/model/items/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14/job_applications?filter[applicant][id][_eq]=${data}`,
      });
      const application = resp.data[0];
      yield put({ type: aT.SET_APPLICATION, data: {...application, aptitude_evaluation_questions: application.aptitude_evaluation_questions ? JSON.parse(application.aptitude_evaluation_questions) : [], technical_evaluation_questions: application.technical_evaluation_questions ? JSON.parse(application.technical_evaluation_questions) : [] }});    } 
      catch (err) {
      yield put({
        type: aT.SET_ERROR,
        data: err.response?.data.errors[0].message,
      });
    }
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onSuccess, res);
  } catch (err) {
    yield put({
      type: aT.SET_ERROR,
      data: err.response?.data.errors[0].message,
    });
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onFailure, err.response.data.errors[0].message);
  }
}

function* getApplication(payload) {
  yield put({ type: aT.SET_LOADING, data: true });
  yield put({ type: aT.SET_ERROR, data: null });
  try {
    const resp = yield call(userApi, {
      method: 'GET',
      url: `/model/items/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14/job_applications?filter[applicant][id][_eq]=${payload.id}`,
    });
    const application = resp.data.data[0];
    yield put({ type: aT.SET_APPLICATION, data: {...application, aptitude_evaluation_questions: JSON.parse(application.aptitude_evaluation_questions), technical_evaluation_questions: JSON.parse(application.technical_evaluation_questions)} });  
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onSuccess, application);
  } catch (err) {
    yield put({
      type: aT.SET_ERROR,
      data: err.response?.data.errors[0].message,
    });
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onFailure, err.response.data.errors[0].message);
  }
}

function* updateApplication(payload) {
  yield put({ type: aT.SET_LOADING, data: true });
  yield put({ type: aT.SET_ERROR, data: null });
  const data = payload.data;
  try {
    const response = yield call(userApi, {
      method: 'PATCH',
      data: data.body,
      url: `/model/items/a6371fd4-8364-4f00-a437-85aae9a94989/a199773e-168b-4cc8-80b6-e696968ddd14/job_applications/${data.id}`,
    });
    const res = response.data.data;
    yield put({ type: aT.SET_APPLICATION, data: {...res, aptitude_evaluation_questions: res.aptitude_evaluation_questions ? JSON.parse(res.aptitude_evaluation_questions) : [], technical_evaluation_questions: res.technical_evaluation_questions ? JSON.parse(res.technical_evaluation_questions) : []} });    
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload.onSuccess, res);
  } catch (err) {
    yield put({ type: aT.SET_LOADING, data: false });
    yield call(payload?.onFailure, err);
  }
}

export function* userLoginSaga(customAxios) {
  userApi = customAxios;
  yield takeLatest(aT.USER_LOGIN, login);
}

export function* getUserSaga(customAxios) {
  userApi = customAxios;
  yield takeLatest(aT.GET_USER, getUser);
}

export function* updateApplicationSaga(customAxios) {
  userApi = customAxios;
  yield takeLatest(aT.UPDATE_APPLICATION, updateApplication);
}

export function* getApplicationSaga(customAxios) {
  userApi = customAxios;
  yield takeLatest(aT.GET_APPLICATION, getApplication);
}

export function* createUserSaga(customAxios) {
  userApi = customAxios;
  yield takeLatest(aT.CREATE_USER, createUser);
}

export function* getUserByIDSaga(customAxios) {
  userApi = customAxios;
  yield takeLatest(aT.GET_USER_BY_ID, getUserByID);
}
