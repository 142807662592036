import { put, call, takeLatest } from 'redux-saga/effects';
import * as aT from '../actions/actionTypes';

let userApi = null;

function* getLeadership(payload) {
    yield put({ type: aT.SET_LOADING, data: false });
    yield put({ type: aT.SET_ERROR, data: null });
    try {
        const response = yield call(userApi, {
            method: 'GET',
            url: 'https://fleetstudio.com/wp-json/wp/v2/posts',
        });
        const res = response.data;
        yield put({ type: aT.SET_LEADERSHIP, data: res });
        yield call(payload.onSuccess, res);
        yield put({ type: aT.SET_LOADING, data: false });
    } catch (err) {
        yield put({
            type: aT.SET_ERROR,
            // data: err.response.data.errors[0].message,
        });
        yield call(payload.onFailure);
        yield put({ type: aT.SET_LOADING, data: false });
    }
}


export function* getLeadershipSaga(customAxios) {
    userApi = customAxios;
    yield takeLatest(aT.GET_LEADERSHIP, getLeadership);
}