import React from 'react';
import AptitudeContainer from './components/aptitudeContainer'

const Aptitude = (props) => {
  return (
    <>
      <div className="main-wrap flex flex-col justify-start bg-transparent">
        <AptitudeContainer />
      </div>
    </>
  );
};

export default Aptitude;
