import { Button, InlineNotification } from 'carbon-components-react';
import { ArrowRight24, NonCertified32 } from '@carbon/icons-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateApplication } from '../../../store/actions';
import moment from 'moment-timezone';

const Startbutton = (props) => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const minutes = '30';
  const seconds = '00';

  const startEvaluation = () => {
    const randomn20 = state.questions.aptitude.sort(() => 0.5 - Math.random()).slice(0,20);
    const data = { 
        id: state.user.application?.id,
        body: {
            aptitude_evaluation_session_start: moment.tz("Asia/Kolkata").format(),
            aptitude_evaluation_questions: JSON.stringify(randomn20),
        }
    };
    dispatch(updateApplication(data), startTimer, onFailure);
  };

  const onFailure = (error) => {
      console.log(error);
  };

  const startTimer = () => {
    props.startCountdown({
      minutes,
      seconds,
    });
  };

  return (
    <div className="input-component-container">
      <div className="min-w-full flex flex-col justify-center items-center p-16">
        <NonCertified32 className="w-20 h-20" />
        <p className="text-sm mx-auto text-center my-4">
          You have not yet taken the aptitude evaluation
        </p>
        <Button
          className="mx-auto"
          renderIcon={ArrowRight24}
          onClick={startEvaluation}
        >
          Start Aptitude Evaluation
        </Button>
      </div>
      <InlineNotification
        className="mx-auto"
        kind="warning"
        hideCloseButton
        subtitle={
          <span>
            <ul>
              <li>This is a timed evaluation (30 Mins).</li>
              <li>
                Timer will start once you click the "Start Aptitude Evaluation"
                button.
              </li>
              <li>A candidate can take this evaluation only once.</li>
              <li>
                Please make sure you have an un-interrupted internet
                connectivity for the next 30 mins.
              </li>
              <li>
                We do not encourage request to re-take this evaluation due to
                internet / power outages.
              </li>
            </ul>
          </span>
        }
        title=""
      />
    </div>
  );
};

export default Startbutton;
