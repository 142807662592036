import React, { useEffect, useState } from 'react';
import FullPage from '../../components/layout/fullPage';
import Persona from './persona';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByID } from '../../store/actions';
import Technical from './technical';
import Aptitude from './aptitude';
import Offer from './offer';

const Evaluation = () => {
  const state = useSelector((state) => state);
  const [stage, setStage] = useState(0);
  const [currentStage, setCurrentStage] = useState(0);
  useEffect(() => {
    if (state.user && state.user.application) {
      setStage(state.user.application.current_stage);
      setCurrentStage(state.user.application.current_stage);
    }
    // eslint-disable-next-line
  }, [state.user.application]);

  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const localID = localStorage.getItem('fsUserID');
    const profile = state.user.profile;
    if (localID) {
      dispatch(getUserByID(localID), null, notGotUser);
    }
    // eslint-disable-next-line
  }, []);

  const notGotUser = (err) => {
    localStorage.removeItem('fsAT');
    localStorage.removeItem('fsRT');
    localStorage.removeItem('fsUserID');
    history.push('/');
  };

  return (
    state.app.loading ? (
      <FullPage>
        <div className="min-h-full">
          <div className="h-48 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-end" />
        </div>
      </FullPage>
    ) : (
      <FullPage>
        <div className="min-h-full mb-4">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div
              className={`h-48 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-start disable-container bg-white`}
            >
              <div className="text-sm text-white">Stage 1</div>
              <p className="text-2xl text-white font-bold">Persona</p>
            </div>

            <div
              onClick={() => currentStage > 2 && setStage(3)}
              className={`h-48 main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-start ${currentStage < 3 ? 'disable-container' : 'cursor-pointer bg-white'}`}
            >
              <div className={`text-sm ${currentStage < 3 ? ' text-white' : ''}`}>Stage 2</div>
              <p className={`text-2xl font-bold ${currentStage < 3 ? ' text-white' : ''}`}>Aptitude</p>
            </div>

            <div
              onClick={() => currentStage > 3 && setStage(4)}
              className={`h-48 main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-start ${currentStage < 4 ? 'disable-container' : 'cursor-pointer bg-white'}`}>
              <div className={`text-sm ${currentStage < 4 ? ' text-white' : ''}`}>Stage 3</div>
              <p className={`text-2xl font-bold ${currentStage < 4 ? ' text-white' : ''}`}>Technical</p>
            </div>

            <div
              onClick={() => currentStage > 4 && setStage(5)}
              className={`h-48 main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-start ${currentStage < 5 ? 'disable-container' : 'cursor-pointer bg-white'}`}>
              <div className={`text-sm ${currentStage < 5 ? ' text-white' : ''}`}>Stage 4</div>
              <p className={`text-2xl font-bold ${currentStage < 5 ? ' text-white' : ''}`}>Offer</p>
            </div>

          </div>
        </div>
        {(stage === 1 || stage === 2) && <Persona />}
{stage === 3 && <Aptitude />}
{stage === 4 && <Technical />}
{stage === 5 && <Offer />}
      </FullPage>
    )
  );
};

export default Evaluation;
