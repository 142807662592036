import { all, fork } from "redux-saga/effects";

import { api } from "../api";
import * as userSagas from "./user";
import * as questionsSagas from "./questions";
import * as leadershipSagas from "./leadership";
import * as recentTweetsSagas from "./recentTweets";

const customAxios = (payload) => api(payload);

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(userSagas).map((saga) => () => saga(customAxios)),
      ...Object.values(questionsSagas).map((saga) => () => saga(customAxios)),
      ...Object.values(leadershipSagas).map((saga) => () => saga(customAxios)),
      ...Object.values(recentTweetsSagas).map((saga) => () => saga(customAxios)),
    ].map(fork)
  );
}
