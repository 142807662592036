import * as aT from "../actions/actionTypes";

const initialState = {
    aptitude: [],
    technical: []
};

export const questionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case aT.GET_APTITUDE:
            return { ...state };

        case aT.SET_APTITUDE:
            return { ...state, aptitude: action.data };

            case aT.GET_TECHNICAL:
                return { ...state };
    
            case aT.SET_TECHNICAL:
                return { ...state, technical: action.data };

        default:
            return state;
    }
};
