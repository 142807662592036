import * as aT from "../actions/actionTypes";

const initialState = {
  info: null,
  profile: null,
  application: null,
  banks: [],
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case aT.USER_LOGIN:
      return { ...state };

    case aT.CREATE_USER:
      return { ...state };

    case aT.STORE_USER:
      return { ...state, info: action.data };

    case aT.STORE_USER_PROFILE:
      return { ...state, profile: action.data };

    case aT.SET_APPLICATION:
      return { ...state, application: action.data };

    default:
      return state;
  }
};
