import React from 'react';

const Offer = (props) => {
  return (
    <div className="h-48 bg-white main-wrap p-8 border border-gray-200 shadow-md flex flex-col justify-start">
      {/* Replace this content */}
      <div className="text-sm text-gray-600">Stage 4</div>
      <p className="text-2xl font-bold">Offer</p>
      {/* Replace this content */}
    </div>
  );
};

export default Offer;
