import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserByID } from '../../store/actions';
import FSLogo from '../../assets/images/fs-logo.png';
import { SpellCheck20, Logout20 } from '@carbon/icons-react';
import {
  Header,
  HeaderGlobalAction,
  HeaderGlobalBar,
} from 'carbon-components-react/lib/components/UIShell';

const HeaderLayout = () => {
  const history = useHistory();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const localID = localStorage.getItem('fsUserID');
    const profile = state.user.profile;
    if (localID && !profile) {
      dispatch(getUserByID(localID), null, notGotUser);
    }
    // eslint-disable-next-line
  }, []);

  const notGotUser = (err) => {
    localStorage.removeItem('fsAT');
    localStorage.removeItem('fsRT');
    localStorage.removeItem('fsUserID');
    history.push('/');
  };

  const logout = () => {
    localStorage.removeItem('fsAT');
    localStorage.removeItem('fsRT');
    localStorage.removeItem('fsUserID');
    history.push('/');
  };

  return (
    <Header className="relative mb-4 bg-white border border-gray-300 shadow-md" aria-label="InsightX">
      <Link to="/" className="flex justify-center px-4">
        <img src={FSLogo} alt="FS Logo" className="h-6 object-contain" />
        <div className="pt-1 pl-2 font-bold">InsightX</div>
      </Link>
      <HeaderGlobalBar>
      <div
          className="text-gray-600 flex justify-center items-center px-4"
        >
          Hi! {state.user && state.user.profile && state.user.profile.first_name} {state.user && state.user.profile && state.user.profile.last_name}
        </div>
        <HeaderGlobalAction
          aria-label="Evaluation"
          onClick={() => {
            history.push('/evaluation');
          }}
          className="text-gray-600"
        >
          <SpellCheck20 />
        </HeaderGlobalAction>
        <HeaderGlobalAction
          aria-label="Logout"
          onClick={() => {
            logout();
          }}
          className="text-gray-600"
        >
          <Logout20 />
        </HeaderGlobalAction>
      </HeaderGlobalBar>
    </Header>
  );
};

export default HeaderLayout;
