import React from "react";
import { Redirect, Route } from "react-router";

export const isLoggedIn = () => {
  if (
    localStorage.getItem("fsUserID") &&
    localStorage.getItem("fsAT")
  ) {
    return true;
  }
  return false;
};

const PrivateRoute = (props) => {
  const { component, path, ...rest } = props;

  return isLoggedIn() ? (
    <Route {...rest} path={props.path} component={props.component} />
  ) : (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  );
};

export default PrivateRoute;
